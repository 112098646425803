

export class TPPError extends Error {
  code: string;
  status: number;

  constructor(message: string, status: number, code: string) {
    super(message);
    this.status = status;
    this.code = code;
  }

  toJSON() {
    return {
      type: "TPPError",
      code: this.code,
      message: this.message,
    };
  }

  static isJSONError(err: unknown): err is { type: "TPPError", code: string, message: string } {
    return typeof err === "object" && err !== null && (err as any).type === "TPPError";
  }

  static isError(err: unknown): err is TPPError {
    return err instanceof TPPError;
  }
}