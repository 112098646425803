/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-extend-native */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/tagcolors.css'
import './index.css'
import Root from './Root'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from './contexts/Auth/AuthProvider'
import TitleProvider from 'contexts/Title/TitleProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import WindowSizeProvider from 'contexts/WindowSize/provider'

declare global {
  var Rewardful: any
  var rewardful: any
  var __ow: Record<string, any>
}

declare global {
  interface Array<T> {
    asClass: string
    dedupe: (keyFn?: (item: T) => string) => Array<T>
  }
}

Object.defineProperty(Array.prototype, 'asClass', {
  configurable: true,
  get() {
    return this.filter(Boolean).join(' ')
  },
})

Array.prototype.dedupe = function (keyFn?: (item: any) => string) {
  const seen = new Set()
  const key = keyFn || ((item) => item)
  const filtered = this.filter((item: any) => {
    const k = key(item)
    if (seen.has(k)) return false
    seen.add(k)
    return true
  })
  return filtered
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

rewardful('ready', function () {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <WindowSizeProvider>
        <QueryClientProvider client={queryClient}>
          <TitleProvider>
            <AuthProvider>
              <BrowserRouter>
                <Root />
              </BrowserRouter>
            </AuthProvider>
          </TitleProvider>
        </QueryClientProvider>
      </WindowSizeProvider>
    </React.StrictMode>
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
