import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import useBuyers from 'hooks/useBuyers'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { CreateHousedAsinInput, MasterUPCCatalogItem, MSKUGeneration } from 'types/housedAsins'
import { v4 } from 'uuid'

interface Header {
  asin: string
  buyer: string
  bundle: number
  sellable: number
  prepCost: string
  fba: boolean
}

interface Item {
  id: string
  sku: string
  manuallyAdded: boolean
  upc: string
  vendorSKU: string
  unitCost: number
  caseQty: number
  supplier: string
}

const initialHeader: Header = {
  asin: '',
  buyer: '',
  bundle: '' as unknown as number,
  sellable: '' as unknown as number,
  prepCost: '',
  fba: true,
}

const validateAsins = (asins: CreateHousedAsinInput[]) => {
  asins.forEach((asin) => {
    if (!asin.asin) throw new Error('ASIN is required')
    if (!asin.sku) throw new Error('SKU is required')
    if (!asin.bundle) throw new Error('Bundle is required')
    if (!asin.targetPrice) asin.targetPrice = 0
    if (!asin.upc) throw new Error('UPC is required')
    if (!asin.vendorSKU) throw new Error('Vendor SKU is required')
    if (!asin.unitCost) throw new Error('Unit Cost is required')
    if (!asin.caseQty) throw new Error('Case Qty is required')
    if (!asin.supplier) throw new Error('Supplier is required')
    asin.unitCost = Number(asin.unitCost)
    if (isNaN(asin.unitCost)) throw new Error('Unit Cost must be a number')
    asin.caseQty = Number(asin.caseQty)
    if (isNaN(asin.caseQty)) throw new Error('Case Qty must be a number')
    asin.bundle = Number(asin.bundle)
    if (isNaN(asin.bundle)) throw new Error('Bundle must be a number')
    asin.sellable = Number(asin.sellable)
    if (isNaN(asin.sellable)) throw new Error('Sellable Qty must be a number')
    asin.targetPrice = Number(asin.targetPrice)
    if (isNaN(asin.targetPrice)) asin.targetPrice = null as unknown as number
  })
  return asins
}

interface InitialData {
  items: MasterUPCCatalogItem[]
  asin: string
  bundle: number
  sellable: number
}

const generateMsku = (columns: MSKUGeneration["columns"], item: MasterUPCCatalogItem) => {
    const validColumns = columns.filter((col) => col.key in item)
    const getPart = (col: MSKUGeneration["columns"][0]) => {
      let val = item[col.key as keyof MasterUPCCatalogItem] || ""
      if (typeof val !== 'string' && typeof val !== 'number') val = ""
      const prefix = col.prefix || ""
      const suffix = col.suffix || ""
      return `${prefix}${val}${suffix}`
    }
    const sku = validColumns.map(getPart).join('').replace(/[^a-zA-Z0-9-]/g, '')
    return sku
}

const CreateHoused: AwaitableModal<undefined, InitialData> = ({ resolve, open, onCancel, initialData }) => {
  const { buyers } = useBuyers()
  const { prepCosts } = usePrepCosts()
  const { supplierNames } = useSupplierNames()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [header, setHeader] = useState<Header>(initialHeader)
  const [items, setItems] = useState<Item[]>([])
  const [columns, setColumns] = useState<MSKUGeneration["columns"]>()

  useEffect(() => {
    housedAsinsApi.getPreferences().then((res) => {
      setColumns(res.mskuGeneration.columns)
    })
  }, [open])

  useEffect(() => {
    if (!open) return
    if (initialData) {
      const {items, asin, bundle,sellable} = initialData

      const headerData = {
        ...initialHeader,
        asin: asin || '',
        bundle: bundle || 0,
        sellable: sellable || 0,
      }
      setHeader(headerData)
      setItems(
        items.map((item) => ({
          id: item.id,
          sku: columns ? generateMsku(columns, item) : "",
          manuallyAdded: false,
          upc: item.upc,
          vendorSKU: item.vendorSKU,
          unitCost: (typeof item.unitCost === 'number' ? item.unitCost.toFixed(2) : item.unitCost) as unknown as number,
          caseQty: item.casePack,
          supplier: supplierNames?.includes(item.supplier) ? item.supplier : '',
        }))
      )
    }
  }, [open, initialData, supplierNames, columns])

  const addManual = () => setItems((prev) => [...prev, { id: v4(), sku: "", manuallyAdded: true, upc: '', vendorSKU: '', unitCost: 0, caseQty: 0, supplier: '' }])

  const handleSubmit = () => {
    if (submitting) return
    setSubmitting(true)

    const asins = items.map((item) => ({
      ...header,
      upc: item.upc,
      sku: item.sku,
      vendorSKU: item.vendorSKU,
      unitCost: item.unitCost,
      caseQty: item.caseQty,
      supplier: item.supplier,
    }))
    try {
      const validatedAsins = validateAsins(asins)
      housedAsinsApi
        .addHoused(validatedAsins)
        .then(() => {
          resolve()
        })
        .finally(() => setSubmitting(false))
    } catch (e: any) {
      handleError(e.message)
      setSubmitting(false)
    }
  }

  const close = () => {
    if (submitting) return
    onCancel()
  }

  return (
    <Modal open={open} close={close}>
      <div className="w-full rounded-xl max-w-[calc(100vw-4rem)] bg-surface-primary max-h-[calc(100vh-4rem)] h-max min-w-[16rem] overflow-y-auto">
        <div className="flex items-start justify-start p-6 pb-4 gap-2">
          <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
          <div className="flex flex-col items-start gap-1">
            <span className="text-base text-text-primary font-medium">Create ASIN Assets</span>
            <span className="text-sm text-text-secondary">Input ASIN information.</span>
          </div>
        </div>
        <main className="overflow-y-auto flex w-full h-full">
          <div className="flex flex-col bg-surface-primary px-6 gap-2">
            <div className="flex items-center gap-4">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">ASIN</span>
                <input
                  type="text"
                  value={header.asin}
                  onChange={(e) => setHeader((prev) => prev && { ...prev, asin: e.target.value })}
                  className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                  placeholder="ASIN"
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Prep Type</span>
                <select
                  className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                  value={header?.prepCost}
                  onChange={(e) => setHeader((prev) => prev && { ...prev, prepCost: e.target.value })}
                >
                  <option value="">None</option>
                  {prepCosts?.map((prep) => (
                    <option key={prep.id} value={prep.name}>
                      {prep.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Buyer</span>
                <select
                  className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                  value={header.buyer}
                  onChange={(e) => setHeader((prev) => prev && { ...prev, buyer: e.target.value })}
                >
                  <option value="">Select Buyer</option>
                  {buyers?.map(({id, name}) => (
                    <option key={id} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center gap-4 pb-4">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Bundle Qty</span>
                <input
                  type="text"
                  value={header.bundle}
                  onChange={(e) => setHeader((prev) => prev && { ...prev, bundle: e.target.value as unknown as number })}
                  className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                  placeholder="Bundle Qty"
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Estimated Sales</span>
                <input
                  type="text"
                  value={header.sellable}
                  onChange={(e) => setHeader((prev) => prev && { ...prev, sellable: e.target.value as unknown as number })}
                  className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                  placeholder="Sellable Qty"
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Fulfillment Channel</span>
                <select
                  className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                  value={header?.fba.toString()}
                  onChange={(e) => setHeader((prev) => prev && { ...prev, fba: e.target.value === 'true' })}
                >
                  <option value="true">FBA</option>
                  <option value="false">FBM</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-4 py-6 border-y border-y-border-primary">
              {items.map((item, index) => (
                <div key={item.id} className="items-end gap-4 grid grid-cols-7">
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">UPC</span>
                    <input
                      type="text"
                      value={item.upc}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, upc: e.target.value } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      readOnly={!item.manuallyAdded}
                      placeholder="UPC"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Vendor SKU</span>
                    <input
                      type="text"
                      value={item.vendorSKU}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, vendorSKU: e.target.value } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      readOnly={!item.manuallyAdded}
                      placeholder="Vendor SKU"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <div className="flex items-center gap-1">
                      <span className="text-xs text-text-secondary font-medium">MSKU</span>
                    </div>
                    <input
                      type="text"
                      value={item.sku}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, sku: e.target.value } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      placeholder="MSKU"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Unit Cost</span>
                    <input
                      type="text"
                      value={item.unitCost}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, unitCost: e.target.value as unknown as number } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      // readOnly={!item.manuallyAdded}
                      placeholder="Unit Cost"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Case Qty</span>
                    <input
                      type="text"
                      value={item.caseQty}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, caseQty: e.target.value as unknown as number } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      // readOnly={!item.manuallyAdded}
                      placeholder="Case Qty"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Supplier</span>
                    <select
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      value={item.supplier}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, supplier: e.target.value } : p)))}
                    >
                      <option value="">Select Supplier</option>
                      {supplierNames?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button className="button-secondary mb-px" onClick={() => setItems((prev) => prev.filter((_, i) => i !== index))}>
                    Remove
                  </button>
                </div>
              ))}
              <button className="button-primary" onClick={addManual}>
                Add Entry Manually
              </button>
            </div>
          </div>
        </main>
        <div className="flex items-center gap-4 p-4 w-full">
          <button className="button-secondary grow" disabled={submitting} onClick={() => close()}>
            Cancel
          </button>
          <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CreateHoused
