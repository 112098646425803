import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import useSavedPresets from 'hooks/useSavedPresets'
import Tooltip from 'components/Tooltip'
import { handleError } from 'helpers/errors'
import useAwaitableModal from 'hooks/useAwaitableModal'
import AreYouSure from './AreYouSure'
import { SavedPreference } from 'types/user'
import { ITablePreference } from 'types/preferences'

interface Props {
  tableName: string
  apply: (value: ITablePreference) => void
  preference?: ITablePreference
}

const ColumnPresets: FC<Props> = ({apply, tableName, preference}) => {
  const [newName, setNewName] = useState('')

  const savedPreferenceGroup = useMemo(() => {
    return `column-presets-${tableName}`
  }, [tableName])

  const [open, setOpen] = useState(false)
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})

  const { data, createSavedPreference, updateSavedPreference, deleteSavedPreference, submitting } = useSavedPresets<ITablePreference>(savedPreferenceGroup, open)

  const onCreate = useCallback(() => {
    if (!newName) return handleError('Name is required')
    createSavedPreference({ name: newName, value: preference })
  }, [newName, createSavedPreference, preference])

  const onApply = useCallback((preset: ITablePreference) => {
    apply(preset)
    setOpen(false)
  }, [apply])

  const onOverride = useCallback(async (old: SavedPreference<ITablePreference>) => {
    const update = preference
    const sure = await areYouSure({ header: 'Override Preset', description: 'Are you sure you want to override this preset?' }).then(() => true).catch(() => false)
    if (sure) updateSavedPreference({id: old.id, name: old.name, value: update})
  }, [areYouSure, updateSavedPreference, preference])

  const onRemove = useCallback(async (preference: SavedPreference<ITablePreference>) => {
    const sure = await areYouSure({ header: 'Delete Preset', description: 'Are you sure you want to delete this filpresetter?' }).then(() => true).catch(() => false)
    if (sure) deleteSavedPreference(preference.id)
  }, [areYouSure, deleteSavedPreference])

  const close = useCallback(() => {
    if (submitting) return
    setOpen(false)
  }, [submitting])

  useEffect(() => {
    if (open) {
      setNewName('')
    }
  }, [open])

  return (
    <>
      <AreYouSureModal />
      <Modal open={open && !!data} close={close}>
        <div className="flex flex-col gap-4 items-center py-4 bg-white !max-h-[calc(100vh-4rem)] !h-full overflow-hidden rounded-lg w-[32rem] !max-w-[calc(100vw-4rem)]">
          <div className="flex items-center gap-4 w-full px-4">
            <h2 className="text-xl font-bold text-left w-full">Saved Filters</h2>
            <div className="flex gap-4 items-center w-full">
              <input type="text" placeholder="Filter Name" className="input-primary w-full" readOnly={submitting} value={newName} onChange={(e) => setNewName(e.target.value)} />
              <button className="button-primary !text-xs !min-w-max" disabled={submitting} onClick={onCreate}>
                Save Current
              </button>
            </div>
          </div>
          <div className="flex gap-4 grow items-center h-full overflow-y-auto w-full px-4 border-y border-y-border-primary py-4 bg-surface-secondary">
            {data?.map((preset) => (
              <div key={preset.name} className="flex gap-1 items-center w-full bg-surface-primary rounded-lg p-2">
                <span className="text-text-primary text-lg grow">{preset.name}</span>
                <Tooltip text="Load preset" position="top" className="z-[100000000]">
                  <button className="button-secondary !p-1" onClick={() => onApply(preset.value)}>
                    <Icon name="DownloadSimple" width={20} height={20} />
                  </button>
                </Tooltip>
                <Tooltip text="Override with current filter" position="top" className="z-[100000000]">
                  <button className="button-secondary !p-1" onClick={() => onOverride(preset)}>
                    <Icon name="UploadSimple" width={20} height={20} />
                  </button>
                </Tooltip>
                <button className="button-secondary !p-1" onClick={() => onRemove(preset)}>
                  <Icon name="TrashSimple" />
                </button>
              </div>
            ))}
            {data?.length === 0 && <div className="text-text-secondary px-4 py-10 text-center w-full">No saved filters</div>}
          </div>
          <div className="flex items-center justify-center w-full gap-4 mt-2 px-4">
            <button className="button-secondary" disabled={submitting} onClick={close}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <button className="button-secondary !px-2 !py-1 !min-w-max text-xs" onClick={() => setOpen(true)} disabled={submitting || (!data && open) || !preference}>
        Saved Presets
      </button>
    </>
  )
}

export default ColumnPresets
