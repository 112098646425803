import housedAsinsApi from "api/housedAsins";
import { FC, useMemo } from "react";
import DashboardTile from "./DashboardTile";
import { usNumber } from "utils/formatting";

interface Props {
    overview?: Awaited<ReturnType<typeof housedAsinsApi.getMasterCatalogOverview>>
}

const Dashboard: FC<Props> = ({overview}) => {
  const stats = useMemo(() => {
    const revenue = {
      title: 'Revenue',
      color: '#8F20FF',
      value: overview?.reduce((acc, cur) => acc + Number(cur.revenue), 0),
      formatter: (value: number) => `$${usNumber(value)}`,
      breakdowns: overview?.map((o) => ({ supplier: o.supplier, value: Number(o.revenue) })),
    }
    const cogs = {
      title: 'COGS',
      color: '#52CBFF',
      value: overview?.reduce((acc, cur) => acc + Number(cur.cogs), 0),
      formatter: (value: number) => `$${usNumber(value)}`,
      breakdowns: overview?.map((o) => ({ supplier: o.supplier, value: Number(o.cogs) })),
    }
    const profit = {
      title: 'Potential Profits',
      color: '#12B76A',
      value: overview?.reduce((acc, cur) => acc + Number(cur.profit), 0),
      formatter: (value: number) => `$${usNumber(value)}`,
      breakdowns: overview?.map((o) => ({ supplier: o.supplier, value: Number(o.profit) })),
    }
    const margin = {
      title: 'Margin',
      color: '#F0A30D',
      value: profit.value && revenue.value ? (profit.value * 100) / revenue.value : undefined,
      formatter: (value: number) => `${usNumber(value)}%`,
      breakdowns: overview?.map((o) => ({ supplier: o.supplier, value: (Number(o.profit) * 100) / Number(o.revenue) })),
    }
    const roi = {
      title: 'ROI',
      color: '#60A5FA',
      value: profit.value && cogs.value ? (profit.value * 100) / cogs.value : undefined,
      formatter: (value: number) => `${usNumber(value)}%`,
      breakdowns: overview?.map((o) => ({ supplier: o.supplier, value: (Number(o.profit) * 100) / Number(o.cogs) })),
    }
    return [revenue, cogs, profit, margin, roi]
  }, [overview])

    return (
      <div className="w-full overflow-x-auto mt-4 overflow-y-visible shrink-0">
        <div className="grid grid-cols-5 w-full gap-4 px-4 min-w-max overflow-y-visible">
          {
            stats.map((stat, i) => (
              <DashboardTile
                key={i}
                {...stat}
              />
            ))
          }
        </div>
      </div>
      )
}

export default Dashboard;