import { colors } from 'components/BadgeSelect'
import Modal from 'components/Modal'
import { FC, useCallback, useMemo, useState } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'
import { ActiveShape } from 'recharts/types/util/types'
import { CatalogDashboard, CatalogStatAtState } from 'types/catalog'
import { usNumber } from 'utils/formatting'

export interface DashboardTileProps {
  title: string
  value: CatalogStatAtState[] | string
  color: string
}

export const DashboardTile: FC<DashboardTileProps> = ({ title, value, color }) => {
  const isButton = value !== undefined || value === '...'
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const data = useMemo(() => {
    if (!value) return []
    if (typeof value === 'string') return []
    return value.sort((a, b) => b.value - a.value)
  }, [value])

  const total = useMemo(() => {
    if (typeof value === 'string') return '...'
    return value.reduce((acc, curr) => acc + curr.value, 0)
  }, [value])

  const renderActiveShape: ActiveShape<PieSectorDataItem> = useCallback(
    (props: PieSectorDataItem) => {
      const { cx = 0, cy = 0, innerRadius, outerRadius = 0, startAngle, endAngle, stroke } = props
      const dataPoint = data.at(activeIndex)

      return (
        <g>
          <text x={cx} y={cy} fontSize={14} dy={-12} fontWeight={700} textAnchor="middle" fill={stroke}>
            {dataPoint?.name}
          </text>
          <text x={cx} y={cy} fontSize={16} dy={8} fontWeight={600} textAnchor="middle" fill="#222">
            {title}:
          </text>
          <text x={cx} y={cy} dy={36} fontSize={24} fontWeight={700} textAnchor="middle" fill={stroke}>
            ${usNumber(dataPoint?.value)}
          </text>
          <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={stroke} stroke={stroke} />
          <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 12} fill={stroke} stroke={stroke} />
        </g>
      )
    },
    [activeIndex, data, title]
  )

  const loading = value === '...'

  return (
    <div
      className={
        [
          'w-full h-24 flex flex-col rounded-lg bg-surface-primary p-4 gap-2 border border-border-primary',
          loading && 'animate-pulse',
          isButton ? 'cursor-pointer hover:opacity-90 transition-all' : 'cursor-default',
        ].asClass
      }
      onClick={() => (isButton ? setOpen(true) : {})}
    >
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-sm text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">$&nbsp;{typeof value === 'string' ? value : usNumber(total as number)}</span>
      <Modal close={() => setOpen(false)} open={open}>
        <div
          className="bg-surface-secondary rounded-xl
            grid-rows-2 grid-cols-1 max-h-[calc(100vh-2rem)]
            lg:grid-cols-2 lg:grid-rows-1 lg:h-auto lg:!w-[58rem]
            grid max-w-[calc(100vw-2rem)] overflow-hidden p-4 gap-4"
        >
          <div className="w-full h-full flex flex-col p-4 bg-surface-primary rounded-lg border border-border-primary drop-shadow-md">
            <span className="text-left text-lg font-bold text-text-primary">{title}</span>
            <div className="h-full aspect-square">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={512} height={512}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius="65%"
                    outerRadius="90%"
                    paddingAngle={2}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length].text} stroke={colors[index % colors.length].text} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="w-full h-full aspect-square p-4 flex flex-col gap-2 bg-surface-primary rounded-lg border border-border-primary drop-shadow-md">
            <span className="text-left text-lg font-bold text-text-primary">{title} by Inventory Status</span>
            <span className="text-left text-base font-medium text-text-primary pb-2">Total: {typeof value === 'string' ? value : `$${usNumber(total as number)}`}</span>
            <div className="rounded-lg overflow-y-auto h-full">
              <table className="w-full h-max">
                <thead className="sticky top-0 bg-surface-primary">
                  <th>Status</th>
                  <th>{title}</th>
                  <th>Percentage</th>
                </thead>
                <tbody onMouseLeave={() => setActiveIndex(0)}>
                  {data.map((d, i) => (
                    <tr key={i} onMouseEnter={() => setActiveIndex(i)} className={i === activeIndex ? 'bg-surface-secondary' : ''}>
                      <td>{d.name}</td>
                      <td>${usNumber(d.value)}</td>
                      <td>{typeof total === 'string' ? '...' : ((d.value / total) * 100).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

interface DashboardProps {
  dashboard?: CatalogDashboard
}

const Dashboard: FC<DashboardProps> = ({ dashboard }) => {
  const tiles = [
    {
      title: 'Total Revenue Value',
      value: dashboard ? dashboard.revenue : ('...' as const),
      color: '#8F20FF',
    },
    {
      title: 'Total Net Proceeds',
      value: dashboard ? dashboard.net : ('...' as const),
      color: '#F0A30D',
    },
    {
      title: 'Total Cost',
      value: dashboard ? dashboard.cogs : ('...' as const),
      color: '#52CBFF',
    },
    {
      title: 'Total Profit',
      value: dashboard ? dashboard.profit : ('...' as const),
      color: '#12B76A',
    },
  ]

  return (
    <div className="w-full overflow-x-auto mt-4 overflow-y-visible shrink-0">
      <div className="grid grid-cols-4 w-full gap-4 px-4 min-w-max overflow-y-visible">
        {tiles.map((tile, i) => (
          <DashboardTile key={i} {...tile} />
        ))}
      </div>
    </div>
  )
}

export default Dashboard
