import { AxiosInstance } from 'axios'
import axios from './axios'
import { IApiFilter } from 'types/tableFiltering'
import { IWarehouseInboundDashboard, IWarehouseInboundShipment, IWarehouseInboundShipmentCursor, IWarehouseInboundShipmentItem, IWarehouseInboundShipmentItemWithProductData, IWarehouseInventoryDashboard, IWarehouseInventoryItem, IWarehouseOutboundDashboard, IWarehouseOutboundShipment } from 'types/warehouse'
import { timeout } from 'utils/timeout'

class WarehouseApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getWarehouseInboundDashboard() {
    return this.axios.get('/warehouse/inbound/dashboard').then((res) => (res.data as IWarehouseInboundDashboard))
  }

  async getWarehouseInboundShipments({page = 1, search, filters, ordering}: {page?: number} & IApiFilter) {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/warehouse/inbound?${sp.toString()}`).then((res) => (res.data as { next: number | null; items: IWarehouseInboundShipment[] }))
  }

  async deleteWarehouseInboundShipments({search, filters, ids, except}: IApiFilter & {ids?: string[], except?: string[]}) {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return axios.post(`/warehouse/inbound/delete?${sp.toString()}`, {ids, except}) 
  }

  async getWarehouseInboundShipment(id: string) {
    return this.axios.get(`/warehouse/inbound/${id}`).then((res) => (res.data as {
      shipment: IWarehouseInboundShipment,
      cursor: IWarehouseInboundShipmentCursor
    }))
  }

  async getWarehouseInboundShipmentItem(id: string, itemId: string) {
    return this.axios.get(`/warehouse/inbound/${id}/items/${itemId}`).then((res) => (res.data as IWarehouseInboundShipmentItemWithProductData))
  }

  async updateWarehouseInboundShipmentItem(id: string, itemId: string, data: any) {
    return this.axios.patch(`/warehouse/inbound/${id}/items/${itemId}`, data).then((res) => (res.data as IWarehouseInboundShipmentItem))
  }

  async arriveWarehouseInboundShipment(id: string) {
    return this.axios.post(`/warehouse/inbound/${id}/arrive`).then((res) => (res.data as IWarehouseInboundShipment))
  }

  async getWarehouseInventoryItems({page = 1, search, filters, ordering}: {page?: number} & IApiFilter) {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/warehouse/inventory?${sp.toString()}`).then((res) => (res.data as { next: number | null; items: IWarehouseInventoryItem[] }))
  }

  async deleteWarehouseInventoryItems({search, filters, ids, except}: IApiFilter & {ids?: string[], except?: string[]}) {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return axios.post(`/warehouse/inventory/delete?${sp.toString()}`, {ids, except}) 
  }

  async getWarehouseInventoryDashboard() {
    return this.axios.get(`/warehouse/inventory/dashboard`).then((res) => (res.data as IWarehouseInventoryDashboard))
  }

  async uploadInventory(uri: string) {
    const timezoneOffset = Math.floor(new Date().getTimezoneOffset() / 60)
    return this.axios.post(`/warehouse/inventory/bulk`, { uri, timezoneOffset })
  }

  async getSyncStatus() {
    // return this.axios.get('/warehouse/outbound/sync/status').then((res) => res.data as {synced: boolean, syncedUntil: string})
    return {synced: true, syncedUntil: new Date().toISOString()}
  }

  async sync() {
    await timeout(60000)
    return this.getSyncStatus()
    // long running operation, firebase hosting redirect fails after a minute
    /*const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api';
    const url = `${base}/warehouse/outbound/sync`

    return this.axios.get(url).then((res) => res.data as {synced: boolean, syncedUntil: string})*/
  }

  async getWarehouseOutboundItems({page = 1, search, filters, ordering}: {page?: number} & IApiFilter) {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/warehouse/outbound?${sp.toString()}`).then((res) => (res.data as { next: number | null; items: IWarehouseOutboundShipment[] }))
  }

  async getWarehouseOutboundDashboard() {
    return this.axios.get(`/warehouse/outbound/dashboard`).then((res) => (res.data as IWarehouseOutboundDashboard))
  }
}

const warehouseApi = new WarehouseApi()

export default warehouseApi
