import { useQuery } from "@tanstack/react-query"
import api from "api"

export const useBasePricePreferences = () => {
    const {data} = useQuery({
        queryKey: ['basePricePreferences'],
        queryFn: api.getBasePricePreferences
    })

    return data
}