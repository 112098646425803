import { FC, PropsWithChildren, useEffect, useState } from 'react'
import titleContext from './titleContext'
import { isDemo } from 'constants/demo'
import { logEvent } from 'firebase/analytics'
import firebase from 'config/firebase'

const TitleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [text, setText] = useState<string>()

  useEffect(() => {
    const textToSet = text?.replace('__back__', '').replace('__back2__', '')
    document.title = [isDemo ? '[DEMO]' : '', textToSet, 'Third Party Profits'].filter(Boolean).join(' | ')
    logEvent(firebase.analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    })
  }, [text])

  return <titleContext.Provider value={{ text, setText }}>{children}</titleContext.Provider>
}

export default TitleProvider
