import { useQuery, useQueryClient } from '@tanstack/react-query'
import purchaseOrdersApi from 'api/purchaseOrders'
import FullLoader from 'components/loaders/FullLoader'
import useTitle from 'contexts/Title/useTitle'
import usePrepCosts from 'hooks/usePrepCosts'
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Dashboard from './components/Dashboard'
import Overview from './components/Overview'
import Table from 'components/tables/Table'
import columnDef from './tableDef'
import { IPurchaseOrderColumnItem, exportPurchaseOrder, exportPurchaseOrder2D, exportPurchaseOrderIL, getPurchaseOrderItemColums } from 'utils/purchaseOrders'
import { frontendFilter } from 'hooks/frontendFilter'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Item from './components/Item'
import { IPurchaseOrderItem } from 'types/purchaseOrders'
import withFiltering from 'contexts/Filter/wrapper'
import Loader from 'components/loaders/Loader'
import Icon from 'assets/icons/iconset'
import useSelect from 'hooks/useSelect'
import useBulkDelete from 'hooks/useBulkDelete'
import useBulkAction from 'hooks/useBulkAction'
import useAwaitableModal from 'hooks/useAwaitableModal'
import InboundModal from './components/InboundModal'
import AddAsin from './components/AddAsin'
// import LockButton from 'components/buttons/LockButton'
import { Row } from '@tanstack/react-table'
import Tooltip from 'components/Tooltip'
import AreYouSure from 'components/modals/AreYouSure'
import UpdateColumns from 'components/modals/UpdateColums'
import { useBasePricePreferences } from 'hooks/useBasePricePreferences'
import UpdatePreferencesModal from './components/UpdatePreferencesModal'

const PurchaseOrderPage: FC = () => {
  const { id, status } = useParams<{ id: string; status: 'open' | 'closed' }>()
  const open = status === 'open'
  const { data } = useQuery({
    queryKey: ['purchaseOrder', id],
    queryFn: () => purchaseOrdersApi.getPurchaseOrder(id!),
  })
  const preferences = useBasePricePreferences()

  const basePrice = useMemo(() => preferences?.purchaseOrders || 'latest', [preferences?.purchaseOrders])

  const filters = useFilteringContext()
  const { prepCosts } = usePrepCosts()
  const { search, setSearch, searching } = filters
  const select = useSelect()
  const [adding, setAdding] = useState(false)

  const items = useMemo(() => {
    if (!data) return
    const transformed = data.purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, basePrice, data.shippingCostDef, prepCosts))
    const filtered = frontendFilter(transformed, {
      ...filters,
      filters: filters.opFilters,
    })
    return filtered.map((item) => ({ __raw: data.purchaseOrder.items.find((i) => i.id === item.id)!, ...item })) as ({ __raw: IPurchaseOrderItem } & IPurchaseOrderColumnItem)[]
  }, [prepCosts, basePrice, data, filters])

  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, purchaseOrdersApi.deletePurchaseOrderItems.bind(this, id!), {
    header: 'Delete Items',
    description: 'Are you sure you want to delete these entries?',
  })
  const [onCreateBackOrder, creatingBackOrder] = useBulkAction(select, purchaseOrdersApi.createBackOrder.bind(this, id!), '/app/purchase-orders/open')
  const [onBackToWorking, backingToWorking] = useBulkAction(select, purchaseOrdersApi.backToWorking.bind(this, id!), '/app/purchase-orders/working')
  const [areYouSure, AreYouSureComponent] = useAwaitableModal(AreYouSure, {header: "Are you sure?", description: "Are you sure you want to submit an uneven number of cases?"})
  const [submit, InboundModalComponent] = useAwaitableModal(InboundModal, select)
  const [changePreferences, UpdatePreferences] = useAwaitableModal(UpdatePreferencesModal, undefined)

  useTitle('__back__Purchase Order')

  const qc = useQueryClient()

  const hasUnevenCases = useMemo(() => {
    if (select.selected.length === 0) return items?.some((item) => Math.floor(item.cases) !== item.cases)
    return select.selected.some((id) => {
      const item = items?.find((i) => i.id === id)
      if (!item) return false
      return Math.floor(item.cases) !== item.cases 
    })
  }, [select.selected, items])

  const validatedSubmit = useCallback(
    () => {
      if (hasUnevenCases) {
        areYouSure().then(() => submit(select))
      } else {
        submit(select)
      }
    }
  , [hasUnevenCases, select, submit, areYouSure])

  const onUpdate = useCallback(
    (entry: Partial<IPurchaseOrderItem>) => {
      qc.setQueryData(['purchaseOrder', id], (old: typeof data) => {
        if (!old) return old
        return {
          ...old,
          purchaseOrder: {
            ...old.purchaseOrder,
            items: old.purchaseOrder.items.map((i: IPurchaseOrderItem) => (i.id === entry.id ? { ...i, ...entry } : i)),
          },
        }
      })
    },
    [id, qc]
  )

  const exportExcel = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      if (!data || !items) return
      const filteredPO = { ...data.purchaseOrder }
      filteredPO.items = items?.map(({ __raw }) => __raw)
      exportPurchaseOrder(filteredPO, basePrice, data.shippingCostDef, prepCosts)
    },
    [data, basePrice, items, prepCosts]
  )

  const exportExcelIL = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      if (!data || !items) return
      const filteredPO = { ...data.purchaseOrder }
      filteredPO.items = items?.map(({ __raw }) => __raw)
      exportPurchaseOrderIL(filteredPO, basePrice, data.shippingCostDef, prepCosts)
    },
    [data, basePrice, items, prepCosts]
  )

  const exportExcel2D = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      if (!data || !items) return
      const filteredPO = { ...data.purchaseOrder }
      filteredPO.items = items?.map(({ __raw }) => __raw)
      exportPurchaseOrder2D(filteredPO, basePrice, data.shippingCostDef, prepCosts)
    },
    [data, basePrice, items, prepCosts]
  )

  const renderRow = useCallback((row: Row<{ __raw: IPurchaseOrderItem } & IPurchaseOrderColumnItem>) => <Item key={row.id} raw={row.original.__raw} row={row as any} onUpdate={onUpdate} />, [onUpdate])

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = creatingBackOrder || deleting || backingToWorking

  const tableId = `purchase-orders-${status}`

  if (!data || !prepCosts) return <FullLoader />

  return (
    <div className="w-full h-full overflow-y-auto p-4 bg-surface-light">
      <AreYouSureComponent />
      <UpdatePreferences />
      <AddAsin id={data.purchaseOrder.id} asins={data.purchaseOrder.items.map((i) => i.asin)} open={adding} setOpen={setAdding} supplier={data.purchaseOrder.supplier} />
      <div className="flex flex-col grow w-full gap-4">
        <Dashboard purchaseOrder={data.purchaseOrder} basePrice={basePrice} shippingCostDef={data.shippingCostDef} prepCosts={prepCosts} />
        <Overview purchaseOrder={data.purchaseOrder} basePrice={basePrice} shippingCostDef={data.shippingCostDef} prepCosts={prepCosts} />
        <div className="flex items-center gap-4">
          <div className="input-box relative">
            <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder={'Search'} className="!px-8" />
            <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
              <Icon name="MagnifyingGlass" className="w-5 h-5" />
              {searching && <Loader size={20} />}
            </div>
          </div>
          {open && (
            <>
              <button className="button-primary" onClick={() => setAdding(true)} disabled={bulkInProgress}>
                <Icon name="Plus" className="w-4 h-4" />
                Add New ASIN
              </button>
              {/*<LockButton locked={locked} toggle={setLocked} />*/}
              <Tooltip text="Export Overview" position="top">
                <button className="button-tertiary !p-1" onClick={exportExcel}>
                  <Icon name="Excel" className="w-8 h-8" />
                </button>
              </Tooltip>
              <Tooltip text="Export for IL" position="top">
                <button className="button-tertiary !p-1" onClick={exportExcelIL}>
                  <Icon name="Excel" className="w-8 h-8" />
                </button>
              </Tooltip>
              <Tooltip text="Export for 2D" position="top">
                <button className="button-tertiary !p-1" onClick={exportExcel2D}>
                  <Icon name="Excel" className="w-8 h-8" />
                </button>
              </Tooltip>
              <div className="grow flex gap-4 justify-end">
                {canDoBulk ? (
                  <div className="flex gap-4">
                    <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
                      Delete
                    </button>
                    <button className="button-secondary w-max" onClick={onCreateBackOrder} disabled={bulkInProgress}>
                      Back Order
                    </button>
                    <button className="button-secondary w-max" onClick={onBackToWorking} disabled={bulkInProgress}>
                      Back To Working
                    </button>
                  </div>
                ) : undefined}
                <UpdateColumns table={tableId} columnDef={columnDef} />
                <button className="button-secondary" onClick={changePreferences}>
                  Preferences
                </button>
                <button className="button-primary" onClick={() => validatedSubmit()} disabled={bulkInProgress}>
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <AreYouSureModal />
      <InboundModalComponent />
      <div className="border border-border-primary bg-surface-primary rounded-lg mt-4 h-full overflow-hidden">
        <Table
          name={tableId}
          columns={columnDef}
          initialPinState={{
            left: ['targetPrice', 'image', 'totalCogs'],
          }}
          renderRow={renderRow}
          items={items}
          loading={!prepCosts}
          select={open ? select : undefined}
          locked={!open}
          extra={{
            prepCosts,
          }}
        />
      </div>
    </div>
  )
}

export default withFiltering(PurchaseOrderPage, 'purchase-order')
