import axiosBase, { AxiosError, isAxiosError } from 'axios'
import firebase from '../config/firebase'
import { signOut } from 'firebase/auth'
import {PerformanceTrace, trace} from 'firebase/performance'
import { handleError } from 'helpers/errors'
import { subscriptionRequiredBlockerRef } from 'components/SubscriptionRequiredBlocker'
import { nonDemoBlockerRef } from 'components/NonDemoBlocker'
import { getIdToken } from 'utils/getIdToken'
import { TPPError } from './errors'
import { FirebaseError } from 'firebase/app'

const axios = axiosBase.create({
   baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:5001/third-party-profits/us-central1/api' : 'https://app.thirdpartyprofits.com/api'
})

axios.interceptors.request.use(async (config) => {
    const bearer = await getIdToken();

    config.headers.setAuthorization(`Bearer ${bearer}`)

    const reqTrace = trace(firebase.performance, "axios-request");
    const url = config.url || "";
    const filteredPrefixes = ["/amazon/products/pictures/"]
    const isFiltered = filteredPrefixes.some((prefix) => url.startsWith(prefix));
    if (!isFiltered) {
      const path = url.split("?")[0];
      reqTrace.putAttribute("path", path);
      reqTrace.putAttribute("method", config.method || "");
      reqTrace.start();

      (config as any).reqTrace = reqTrace;
    }

    return config
})

axios.interceptors.response.use((res) => {
    const reqTrace = (res.config as any).reqTrace as PerformanceTrace | undefined;
    if (reqTrace) {
        reqTrace.stop();
    }
    return res
}, async (err: AxiosError<({ type?: string} & Record<string, unknown>) | string>) => {
    if (isAxiosError(err)) {
      if (err.response?.status === 401) {
        await signOut(firebase.auth)
        return
      }

      const data = err.response?.data
      if (typeof data !== "object" || Array.isArray(data)) {
        handleError("An unknown error occurred")
        throw err
      }
      if (TPPError.isJSONError(data)) {
        if (data.code === "demo_mode") {
          nonDemoBlockerRef.current?.open()
          throw err
        }
        if (data.code === "subscription_required") {
          subscriptionRequiredBlockerRef.current?.open()
          throw err
        }
        handleError(data.message)
        throw err
      }
      if (data.type === "FirebaseError") {
        const firebaseError = new FirebaseError(data.code as string, data.message as string)
        handleError(firebaseError.message)
        throw firebaseError
      }
    }
    
    handleError("An unknown error occurred")
    throw err
})

export default axios
