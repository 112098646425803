import { ColumnDef } from '@tanstack/react-table'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import useFilteringContext from 'contexts/Filter/useFilteringContext'
import useSavedPresets from 'hooks/useSavedPresets'
import { FilterStorage } from 'contexts/Filter/FilteringProvider'
import Tooltip from 'components/Tooltip'
import { handleError } from 'helpers/errors'
import { optionNames } from 'components/tables/ColFilterSelect'
import useAwaitableModal from 'hooks/useAwaitableModal'
import AreYouSure from './AreYouSure'
import { SavedPreference } from 'types/user'

interface Props {
  columnDef: ColumnDef<any>[]
}

const formatPreference = (columnDef: ColumnDef<any>[], value: FilterStorage) => {
  const { filter, ordering } = value
  const getHeader = (key: string) => columnDef.find((col) => col.id === key)?.header as string || key
  const filterString = Object.entries(filter || {})
    .map(([key, { o, v }]) => [
      getHeader(key),
      optionNames[o as keyof typeof optionNames] || o,
      Array.isArray(v) ? v.join(', ') : v,
    ].filter(Boolean).join(' ')).join(',\n')
  const orderingString = ordering ? `Sort by ${getHeader(ordering.by)} ${ordering.direction === 1 ? "ASC" : "DESC"}` : ''
  return [filterString, orderingString].filter(Boolean).join('\n')
}

const FilterPresets: FC<Props> = ({ columnDef }) => {
  const { name: tableName, ordering, opFilters, setOrdering, setOpFilters } = useFilteringContext()

  const [newName, setNewName] = useState('')

  const savedPreferenceGroup = useMemo(() => {
    return `filter-presets-${tableName}`
  }, [tableName])

  const [open, setOpen] = useState(false)
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})

  const { data, createSavedPreference, updateSavedPreference, deleteSavedPreference, submitting } = useSavedPresets<FilterStorage>(savedPreferenceGroup, open)

  const onCreate = useCallback(() => {
    if (!newName) return handleError('Name is required')
    createSavedPreference({ name: newName, value: { filter: opFilters, ordering } }).then(() => setNewName(''))
  }, [newName, opFilters, ordering, createSavedPreference])

  const onApply = useCallback((preset: FilterStorage) => {
    setOpFilters(preset.filter || {})
    setOrdering(preset.ordering)
    setOpen(false)
  }, [setOpFilters, setOrdering])

  const filterActive = useMemo(() => {
    return Object.keys(opFilters).length > 0 || !!ordering?.by
  }, [opFilters, ordering])

  const onOverride = useCallback(async (preference: SavedPreference<FilterStorage>) => {
    const update = { filter: opFilters, ordering }
    if (!filterActive) return handleError('No filter active')
    const sure = await areYouSure({ header: 'Override Filter', description: 'Are you sure you want to override this filter?' }).then(() => true).catch(() => false)
    if (sure) updateSavedPreference({id: preference.id, name: preference.name, value: update})
  }, [areYouSure, updateSavedPreference, opFilters, ordering, filterActive])

  const onRemove = useCallback(async (preference: SavedPreference<FilterStorage>) => {
    const sure = await areYouSure({ header: 'Delete Filter', description: 'Are you sure you want to delete this filter?' }).then(() => true).catch(() => false)
    if (sure) deleteSavedPreference(preference.id)
  }, [areYouSure, deleteSavedPreference])

  const close = useCallback(() => {
    if (submitting) return
    setOpen(false)
  }, [submitting])

  useEffect(() => {
    if (open) {
      setNewName('')
    }
  }, [open])

  return (
    <>
      <AreYouSureModal />
      <Modal open={open && !!data} close={close}>
        <div className="flex flex-col gap-4 items-center py-4 bg-white !max-h-[calc(100vh-4rem)] !h-full overflow-hidden rounded-lg w-[32rem] !max-w-[calc(100vw-4rem)]">
          <div className="flex items-center gap-4 w-full px-4">
            <h2 className="text-xl font-bold text-left w-full">Saved Filters</h2>
            <div className="flex gap-4 items-center w-full">
              <input type="text" placeholder="Filter Name" className="input-primary w-full" readOnly={!filterActive} value={newName} onChange={(e) => setNewName(e.target.value)} />
              <Tooltip text={filterActive ? '' : 'No filter active'} position="left" className="z-[100000000]">
                <button className="button-primary !text-xs !min-w-max" disabled={!filterActive || submitting} onClick={onCreate}>
                  Save Current
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="grid gap-4 grow items-center h-full overflow-y-auto w-full px-4 border-y border-y-border-primary py-4 bg-surface-secondary">
            {data?.map((preset) => (
              <div key={preset.name} className="flex gap-1 items-center w-full bg-surface-primary rounded-lg p-2">
                <span className="text-text-primary text-lg">{preset.name}</span>
                <span className="text-text-secondary text-xs">:</span>
                <span className="whitespace-pre-wrap text-text-primary text-sm grow p-2">
                  {formatPreference(columnDef, preset.value)}
                </span>
                <Tooltip text="Apply preset" position="top" className="z-[100000000]">
                  <button className="button-secondary !p-1" onClick={() => onApply(preset.value)}>
                    <Icon name="DownloadSimple" width={20} height={20} />
                  </button>
                </Tooltip>
                <Tooltip text="Override with current filter" position="top" className="z-[100000000]">
                  <button className="button-secondary !p-1" onClick={() => onOverride(preset)}>
                    <Icon name="UploadSimple" width={20} height={20} />
                  </button>
                </Tooltip>
                <button className="button-secondary !p-1" onClick={() => onRemove(preset)}>
                  <Icon name="TrashSimple" />
                </button>
              </div>
            ))}
            {data?.length === 0 && <div className="text-text-secondary px-4 py-10 text-center w-full">No saved filters</div>}
          </div>
          <div className="flex items-center justify-center w-full gap-4 mt-2 px-4">
            <button className="button-secondary" disabled={submitting} onClick={close}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <button className="button-secondary" onClick={() => setOpen(true)} disabled={submitting || (!data && open)}>
        <Icon name="Funnel" />
        Saved Filters
      </button>
    </>
  )
}

export default FilterPresets
