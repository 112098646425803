import { FC } from "react";

const Switch: FC<{checked: boolean}> = ({checked}) => {
    return (
        <div className={["relative p-0.5 w-10 transition-all rounded-full", checked ? "bg-brand-disabled" : "bg-slate-200"].asClass}>
            <div className={["w-4 h-4 rounded-full transition-all", checked ? "bg-brand-primary translate-x-5" : "bg-slate-400"].asClass} />
        </div>
    )
}

export default Switch