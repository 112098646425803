import api from 'api'
import { AmazonImg } from 'assets'
import IconBig from 'components/IconBig'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useState } from 'react'

const AmazonAccountSettings: FC = () => {
  const user = useUserContext()
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    if (submitting) return
    setSubmitting(true)
    api.getAmazonAuthUri().then((res) => {
      window.open(res, '_self')
    })
  }

  const amazonIds = Object.keys(user?.amazonAccounts ?? {})

  return (
    <>
      <div className="p-4 border-b border-b-slate-200">
        <span className="text-base-900 font-medium text-base">Amazon Account</span>
      </div>
      <div className="flex-cool overflow-y-auto items-center grow">
        <div className="grow flex-cool h-full min-h-max items-center py-10 overflow-y-auto w-full px-4">
          <IconBig name="user-octagon" className="w-32 h-32 mb-8 min-h-32 shrink-0" />
          {amazonIds.length === 0 ? (
            <>
              <span className="text-base-900 font-medium text-2xl">No Amazon Account Connected</span>
              <button disabled={submitting} className="button-blue" children="Connect Account" onClick={onClick} />
            </>
          ) : (
            <>
              <span className="text-base-900 font-medium text-2xl">Amazon Account Connected</span>
              <span className="text-orange-500">{user?.amazonAccounts?.[amazonIds[0]]}</span>
            </>
          )}
        </div>
        <div className="bg-slate-900 flex items-center justify-center w-full py-10">
          <img src={AmazonImg} className="h-8" />
        </div>
      </div>
    </>
  )
}

export default AmazonAccountSettings
