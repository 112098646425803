import { IShippingCost } from "types/housedAsins";
import { IPrepCost } from "types/prepCosts";
import { IPurchaseOrder, IPurchaseOrderItem } from "types/purchaseOrders";
import XLSX from "xlsx-js-style";
import suppliersApi from "api/suppliers";
import { KeepaStats } from "types/warehouse";
import { BasePrice } from "types/user";

const getSellableQty = (item: IPurchaseOrderItem) => {
    const buyQty = item.buyQty;
    const bundle = item.bundle;
    return buyQty / bundle;
}

const getPrice = (item: IPurchaseOrderItem, basePrice: BasePrice) => {
    switch (basePrice) {
        case "latest":
            return item.housedAsin.price;
        case "days30":
            return item.keepaStats?.priceAmazon?.days30;
        case "days90":
            return item.keepaStats?.priceAmazon?.days90;
        case "days180":
            return item.keepaStats?.priceAmazon?.days180;
        case "days365":
            return item.keepaStats?.priceAmazon?.days365;
        default:
            return item.housedAsin.price;
    }
}

const getEstimatedRevenue = (item: IPurchaseOrderItem, basePrice: BasePrice) => {
    const price = Number(item.targetSoldPrice || getPrice(item, basePrice) || "0");
    const sellable = getSellableQty(item);
    return price * sellable;
}

const getCostOfGoods = (item: IPurchaseOrderItem) => {
    const unitCost = Number(item.unitCost || item.housedAsin?.unitCost || "0");
    const buyQty = item.buyQty;
    return unitCost * buyQty;
}

const getWeight = (item: IPurchaseOrderItem) => {
    const weight = Number(item.housedAsin?.weightValue || "0");
    const sellable = getSellableQty(item);
    return weight * sellable;
}

const getFees = (item: IPurchaseOrderItem) => {
    return getSellableQty(item) * +(item.housedAsin?.fees || 0);
}

const getShippingCost = (item: IPurchaseOrderItem, shippingCost?: IShippingCost) => {
    switch (shippingCost?.type) {
        case "per_pound":
            return getWeight(item) * shippingCost.cost;
        case "per_item":
            return getSellableQty(item) * shippingCost.cost;
        default:
            return 0;
    }
}

const getPrepCost = (item: IPurchaseOrderItem, prepCosts?: IPrepCost[]) => {
    const prepCost = prepCosts?.find(cost => cost.name === item.housedAsin?.prepCost);
    return (prepCost?.amount || 0) * getSellableQty(item);
}

const getCases = (item: IPurchaseOrderItem) => {
    return item.buyQty / item.casePack;
}

type KeepaAveragesKeys = "newOfferCounts" | "salesRank" | "priceAmazon" | "priceNew" | "buyBox" | "reviews" | "rating" | "priceFba" | "priceFbm";

const extractKeepaStatColumns = (item: IPurchaseOrderItem) => {
    const stats = item.keepaStats || {} as KeepaStats;

    const extractNonPriceStats = (field: KeepaAveragesKeys) => {
        return {
            [`${field}Latest`]: stats[field]?.latest || null,
            [`${field}Days30`]: stats[field]?.days30 || null,
            [`${field}Days90`]: stats[field]?.days90 || null,
            [`${field}Days180`]: stats[field]?.days180 || null,
            [`${field}Days365`]: stats[field]?.days365 || null,
        }
    };
    
    const extractPriceStats = (field: KeepaAveragesKeys) => {
        return {
            [`${field}Latest`]: stats[field]?.latest ? stats[field]?.latest as number / 100 : null,
            [`${field}Days30`]: stats[field]?.days30 ? stats[field]?.days30 as number / 100 : null,
            [`${field}Days90`]: stats[field]?.days90 ? stats[field]?.days90 as number / 100 : null,
            [`${field}Days180`]: stats[field]?.days180 ? stats[field]?.days180 as number / 100 : null,
            [`${field}Days365`]: stats[field]?.days365 ? stats[field]?.days365 as number / 100 : null,
        }
    };

    const normalFields = [
        "newOfferCounts",
        "salesRank",
        "reviews",
        "rating",
    ] as const;

    const priceFields = [
        "priceAmazon",
        "priceNew",
        "buyBox",
        "priceFba",
        "priceFbm",
    ] as const;

    const normalStats = normalFields.reduce((acc, field) => ({...acc, ...extractNonPriceStats(field)}), {});
    const priceStats = priceFields.reduce((acc, field) => ({...acc, ...extractPriceStats(field)}), {});

    return {
        ...normalStats,
        ...priceStats,
        inStockAmazonPercentage30: stats.inStock?.amazon?.percentage30 || null,
        inStockAmazonPercentage90: stats.inStock?.amazon?.percentage90 || null,
        inStockPercentage30: stats.inStock?.percentage30 || null,
        inStockPercentage90: stats.inStock?.percentage90 || null,
        inStockPercentage180: stats.inStock?.percentage180 || null,
        inStockPercentage365: stats.inStock?.percentage365 || null,
    }
};

export const getPurchaseOrderItemColums = (item: IPurchaseOrderItem, basePrice: BasePrice, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const id = item.id;
    const asin = item.asin;
    const upc = item.housedAsin.upc;
    const vendorSKU = item.housedAsin.vendorSKU;
    const unitCost = +item.unitCost || +(item.housedAsin.unitCost || 0);
    const bundle = item.bundle;
    const buyQty = item.buyQty;
    const sellable = getSellableQty(item);
    const casePack = item.casePack;
    const cases = getCases(item);

    const keepaStats = extractKeepaStatColumns(item);

    const price = item.targetSoldPrice || +(getPrice(item, basePrice) || 0);
    const targetPrice = +item.targetPrice || 0;
    const totalCogs = getCostOfGoods(item);
    const productionCost = getPrepCost(item, prepCosts);
    const prepCost = item.housedAsin.prepCost;
    const shippingCost = getShippingCost(item, shippingCostDef);

    const landedCost = totalCogs + productionCost + shippingCost;

    const revenue = getEstimatedRevenue(item, basePrice) / sellable;
    const fees = getFees(item) / sellable;
    const net = revenue - fees;
    const gross = net - landedCost / sellable;
    const totalGross = gross * sellable;
    const roi = landedCost ? totalGross * 100 / landedCost : null;
    const margin = revenue ? gross * 100 / revenue : null;
    const weight = getWeight(item) / sellable;

    const asinCost = unitCost * buyQty / sellable;

    return {
        id,
        asin,
        title: item.housedAsin.title,
        amazonTitle: item.housedAsin.amazonTitle,
        upc,
        vendorSKU,
        unitCost,
        bundle,
        buyQty,
        sellable,
        casePack,
        cases,
        price,
        targetPrice,
        totalCogs,
        prepCost,
        productionCost,
        shippingCost,
        landedCost,
        revenue,
        fees,
        net,
        totalGross,
        gross,
        roi,
        margin,
        weight,
        sku: item.housedAsin.sku,
        asinCost,
        brand: item.housedAsin.brand,
        salesCategory: item.housedAsin.salesCategory,
        salesRank: item.housedAsin.salesRank,
        order_type: item.orderType,
        notes: item.notes,
        group: item.group,
        storageType: item.housedAsin.storageType,
        ...keepaStats,
    }
}

export type IPurchaseOrderColumnItem = ReturnType<typeof getPurchaseOrderItemColums>;

export type IPurchaseOrderOverview = ReturnType<typeof getPurchaseOrderOverview>;

export const getPurchaseOrderOverview = ({ purchaseOrder, basePrice, shippingCostDef, prepCosts}: { purchaseOrder: IPurchaseOrder, basePrice: BasePrice, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[] }) => {
    const totalEstimatedRevenue = purchaseOrder.items.reduce((acc, item) => acc + getEstimatedRevenue(item, basePrice), 0);

    const totalCOGs = purchaseOrder.items.reduce((acc, item) => acc + getCostOfGoods(item), 0);
    const totalWeight = purchaseOrder.items.reduce((acc, item) => acc + getWeight(item), 0);
    const totalShipping = Number(purchaseOrder.shippingCost) || purchaseOrder.items.reduce((acc, item) => acc + getShippingCost(item, shippingCostDef), 0);
    const totalProduction = purchaseOrder.items.reduce((acc, item) => acc + getPrepCost(item, prepCosts), 0);

    const totalFees = purchaseOrder.items.reduce((acc, item) => acc + getFees(item), 0);
    const totalExpenses = totalShipping + totalProduction + totalCOGs;
    const totalCases = purchaseOrder.items.reduce((acc, item) => acc + getCases(item), 0);
    const totalUnitsPurchased = purchaseOrder.items.reduce((acc, item) => acc + item.buyQty, 0);
    const totalSellableAsins = purchaseOrder.items.reduce((acc, item) => acc + getSellableQty(item), 0);

    const totalNetProceeds = totalEstimatedRevenue - totalFees;
    const totalGrossProfit = totalNetProceeds - totalExpenses;
    const totalLandedCost = totalExpenses;

    const totalMargin = totalEstimatedRevenue ? totalGrossProfit * 100 / totalEstimatedRevenue : null;

    const totalROI = totalLandedCost ? totalGrossProfit * 100 / totalLandedCost : null;
    const buttonText = totalUnitsPurchased || purchaseOrder.status === "closed" ? "View Purchase Order" : "Begin Purchase Order";

    return {
        totalEstimatedRevenue,
        totalCOGs,
        totalWeight,
        totalShipping,
        totalProduction,
        totalExpenses,
        totalCases,
        totalUnitsPurchased,
        totalSellableAsins,
        totalNetProceeds,
        totalGrossProfit,
        totalMargin,
        totalFees,
        totalROI,
        buttonText,
        totalLandedCost,
        buyer: purchaseOrder.buyer ? purchaseOrder.buyer.name : null,
    }
}

export const exportPurchaseOrder = async (purchaseOrder: IPurchaseOrder, basePrice: BasePrice, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[], ) => {
    const supplier = await suppliersApi.getSuppliers({filters: {"name": {"o": "=", "v": purchaseOrder.supplier}}}).then((res) => res.suppliers[0]).catch(() => null) || null;
    const items = purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, basePrice, shippingCostDef, prepCosts))
    const createdAt = purchaseOrder.createdAt;

    const overview = getPurchaseOrderOverview({
        purchaseOrder,
        shippingCostDef,
        prepCosts,
        basePrice,
    })

    const transformItem = (item: ReturnType<typeof getPurchaseOrderItemColums>, index: number) => {
      return {
        "PRODUCT DESCRIPTION": item.title || item.amazonTitle,
        "ITEM CODE": item.vendorSKU,
        "UPC": item.upc,
        "UNIT QTY": item.buyQty,
        "CASE PACK": item.casePack,
        "ORDER CASE QTY": item.cases,
        "CURRENT UNIT COST": `$${item.unitCost.toFixed(2)}`,
        "ASK PRICE": `$${item.targetPrice.toFixed(2)}`,
        "RECEIVED PRICE": "",
        "TOTAL COSTS LINE EXTENDED": `$${((item.buyQty || 1) * item.unitCost).toFixed(2)}`,
        ...(index === 0 && {"TOTAL PO": `$${overview.totalCOGs.toFixed(2)}`}),
        ...(index === 0 && {"TOTAL CASES": `$${overview.totalCases.toFixed(2)}`}),
      }
    }
    const workbook = XLSX.utils.book_new()
    const headers = ["PRODUCT DESCRIPTION", "ITEM CODE", "UPC", "UNIT QTY", "CASE PACK", "ORDER CASE QTY", "CURRENT UNIT COST", "ASK PRICE", "RECEIVED PRICE", "TOTAL COSTS LINE EXTENDED", "TOTAL PO", "TOTAL CASES"] as const
    const shippingHeaderSheet = XLSX.utils.aoa_to_sheet([
        [{v: "COMPANY NAME:", t: "s", s: {font: {bold: true}}}, supplier?.storeName || ""],
        [{v: "ACCOUNT NUMBER:", t: "s", s: {font: {bold: true}}}, supplier?.accountNumber || ""],
        [{v: "BILL TO ADDRESS:", t: "s", s: {font: {bold: true}}}, supplier?.billingAddress || ""],
        [{v: "SHIP TO ADDRESS:", t: "s", s: {font: {bold: true}}}, supplier?.shippingAddress || ""],
        [""],
    ])
    const header = headers.map((v) => {
        return {v, t: "s", s: {font: {bold: true}}}
    })
    const itemRows = items.map(transformItem)
    const productRows = itemRows.map((row) => (
        headers.map((key) => ({v: row[key], t: "s"})
    )))
    const sheet = XLSX.utils.sheet_add_aoa(shippingHeaderSheet, [header, ...productRows], {origin: "A6"})
    sheet["!cols"] = headers.map((header) => ({wch: Math.max(16, Math.round(header.length * 1.5))}))

    XLSX.utils.book_append_sheet(workbook, sheet)

    const name = `PO_${purchaseOrder.supplier}_${new Date(createdAt).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-')}.xlsx`
    XLSX.writeFile(workbook, name, {bookType: "xlsx"})
  }

export const exportPurchaseOrderIL = (purchaseOrder: IPurchaseOrder, basePrice: BasePrice, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const transformItem = (item: ReturnType<typeof getPurchaseOrderItemColums>, i: number) => {
        const rawItem = purchaseOrder.items[i]
        return {
            ASIN: item.asin,
            TITLE: item.title || item.amazonTitle,
            "BUY COST": `$${item.unitCost.toFixed(2)}`,
            "LIST PRICE": `$${((rawItem.housedAsin.price || 0) * 1.2).toFixed(2)}`,
            "BUNDLE QUANTITY": item.bundle,
            "BUY QUANTITY": item.buyQty,
            "SELLABLE QUANTITY": item.sellable,
            "DATE PURCHASED": purchaseOrder.createdAt,
            SUPPLIER: purchaseOrder.supplier,
            CONDITION: "New",
            MSKU: item.sku,
            UPC: item.upc,
            "VENDOR SKU": item.vendorSKU,
            "CASE PACK": item.casePack,
            NOTES: item.notes || "",
            BRAND: item.brand || "",
            "AMZ LINK": `https://www.amazon.com/dp/${item.asin}`,
            "PRODUCT TITLE": item.title,
            "TARGET SELL PRICE": `$${Number(rawItem.targetSoldPrice || "0")?.toFixed(2)}`,
            "NET PROCEEDS": `${item.net.toFixed(2)}`,
            WEIGHT: item.weight,
            "STORAGE TYPE": item.storageType || "",
            "PREP TYPE": item.prepCost,
        }
    }

    const items = purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, basePrice, shippingCostDef, prepCosts))
    const itemRows = items.map(transformItem)
    const headers = ["ASIN", "TITLE", "BUY COST", "LIST PRICE", "BUNDLE QUANTITY", "BUY QUANTITY", "SELLABLE QUANTITY", "DATE PURCHASED", "SUPPLIER", "CONDITION", "MSKU", "UPC", "VENDOR SKU", "CASE PACK", "NOTES", "BRAND", "AMZ LINK", "PRODUCT TITLE", "TARGET SELL PRICE", "NET PROCEEDS", "WEIGHT", "STORAGE TYPE", "PREP TYPE"] as const
    const workbook = XLSX.utils.book_new()
    const header = headers.map((v) => {
        return {v, t: "s", s: {font: {bold: true}}}
    })
    const productRows = itemRows.map((row) => (
        headers.map((key) => ({v: row[key], t: "s"})
    ))
    )
    const sheet = XLSX.utils.aoa_to_sheet([header, ...productRows])
    XLSX.utils.book_append_sheet(workbook, sheet)
    const name = `PO_IL_${purchaseOrder.supplier}_${new Date(purchaseOrder.createdAt).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-')}.xlsx`

    XLSX.writeFile(workbook, name, {bookType: "xlsx"})
}

export const exportPurchaseOrder2D = (purchaseOrder: IPurchaseOrder, basePrice: BasePrice, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const transformItem = (item: ReturnType<typeof getPurchaseOrderItemColums>) => {
        return {
            SKU: item.sku,
            QTY: item.sellable,
            UNITS_PER_CASE: "",
            LOCATION: "",
            NOTES: item.notes || "",
            "MM-DD-YYYY": "",
            EXPIRY_TEXT: "",
            TITLE: item.title,
            UPC: item.upc
        }
    }
    const items = purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, basePrice, shippingCostDef, prepCosts))
    const itemRows = items.map(transformItem)
    const headers = ["SKU", "QTY", "UNITS_PER_CASE", "LOCATION", "NOTES", "MM-DD-YYYY", "EXPIRY_TEXT", "TITLE", "UPC"] as const
    const workbook = XLSX.utils.book_new()
    const header = headers.map((v) => {
        return {v, t: "s", s: {font: {bold: true}}}
    })
    const productRows = itemRows.map((row) => (
        headers.map((key) => ({v: row[key], t: "s"})
    ))
    )
    const sheet = XLSX.utils.aoa_to_sheet([header, ...productRows])
    XLSX.utils.book_append_sheet(workbook, sheet)
    const name = `PO_2D_${purchaseOrder.supplier}_${new Date(purchaseOrder.createdAt).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-')}.xlsx`
    XLSX.writeFile(workbook, name, {bookType: "xlsx"})
}