import Icon from 'assets/icons/iconset'
import Loader from 'components/loaders/Loader'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { FC, ReactNode } from 'react'

interface props {
  topElement?: ReactNode
  searchOverride?: ReactNode
  searchPlaceholder?: string
  afterSearch?: ReactNode
  underSearch?: ReactNode
  buttons?: ReactNode
  children: ReactNode
  wrapperClass?: string
}

const ContentLayout: FC<props> = ({ children, topElement, afterSearch, searchOverride, buttons, searchPlaceholder, underSearch, wrapperClass = '' }) => {
  const { searchState, setSearch, searching } = useFilteringContext()
  return (
    <div className="flex flex-col h-full bg-surface-light w-full p-4 overflow-hidden gap-4">
      {topElement}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-4 flex-wrap w-full overflow-hidden">
          <div className="flex items-center gap-4 min-w-max">
            {searchOverride ? (
              searchOverride
            ) : (
              <div className="input-box relative">
                <input type="text" value={searchState} onChange={(e) => setSearch(e.currentTarget.value)} placeholder={searchPlaceholder || 'Search'} className="!px-8" />
                <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                  <Icon name="MagnifyingGlass" className="w-5 h-5" />
                  {searching && <Loader size={20} />}
                </div>
              </div>
            )}
            {afterSearch}
          </div>
          <div className="overflow-x-auto [&>div]:w-max max-w-full">{buttons}</div>
        </div>
        {!!underSearch && (
          <div className="overflow-x-auto [&>div]:w-max w-full">{underSearch}</div>
        )}
      </div>
      <div className={['flex w-full grow overflow-hidden rounded-lg bg-surface-primary border border-border-primary', wrapperClass].asClass}>{children}</div>
    </div>
  )
}

export default ContentLayout
