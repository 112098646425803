import { Dispatch, SetStateAction, createContext } from "react";
import { IOrdering } from "types/housedAsins";
import { IOperatorFilter, IPeriodFilter } from "types/tableFiltering";

export interface FilteringContextType {
    name: string,
    ordering: IOrdering | undefined,
    setOrdering: Dispatch<SetStateAction<IOrdering | undefined>>,
    search: string,
    searchState: string,
    setSearch: Dispatch<SetStateAction<string>>,
    searching: boolean,
    setSearching: Dispatch<SetStateAction<boolean>>,
    opFilters: Record<string, IOperatorFilter>,
    setOpFilters: Dispatch<SetStateAction<Record<string, IOperatorFilter>>>    
    period: IPeriodFilter
    setPeriod: Dispatch<SetStateAction<IPeriodFilter>>
}

const FilteringContext = createContext<FilteringContextType>({} as FilteringContextType)

export default FilteringContext