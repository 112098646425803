import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { FC, useEffect, useState } from 'react'

interface Props {
  refresh: () => void
}

const RefreshAfterUpload: FC<Props> = ({ refresh }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const url = new URL(window.location.href)
    if (url.searchParams.has('imported')) {
      setOpen(true)
      url.searchParams.delete('imported')
      window.history.replaceState(window.history.state, document.title, url.href)
    }
  }, [])

  return (
    <Modal open={open} close={() => setOpen(false)}>
      <div className="w-full rounded-xl max-w-[calc(100vw-4rem)] bg-surface-primary max-h-[calc(100vh-4rem)] h-max min-w-[16rem] overflow-y-auto">
        <div className="flex items-start justify-start p-6 pb-4 gap-2">
          <Icon name="Refresh" className="w-6 h-6 text-brand-primary" />
          <div className="flex flex-col items-start gap-1">
            <span className="text-base text-text-primary font-medium">Make sure to refresh</span>
            <span className="text-sm text-text-secondary">To see the uploaded items, remember to hit the refresh button.</span>
          </div>
        </div>
        <div className="flex items-center gap-4 p-4 w-full">
          <button className="button-secondary grow" onClick={() => setOpen(false)}>
            Refresh Later
          </button>
          <button
            className="button-primary grow"
            onClick={() => {
              refresh()
              setOpen(false)
            }}
          >
            Refresh
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RefreshAfterUpload
