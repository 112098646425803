import { useQuery } from '@tanstack/react-query'
import restockingApi from 'api/restocking'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { FC } from 'react'
import { IApiFilter } from 'types/tableFiltering'
import { usNumber } from 'utils/formatting'

interface DashboardTileProps {
  title: string
  value: string | number
  color: string
}

export const DashboardTile: FC<DashboardTileProps> = ({ title, value, color }) => {
  const loading = value === '...'
  return (
    <div className={[
          'w-full h-24 flex flex-col rounded-lg bg-surface-primary p-4 gap-2 border border-border-primary',
      loading && 'animate-pulse'].asClass}>
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-sm text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">$&nbsp;{typeof value === 'string' ? value : usNumber(value)}</span>
    </div>
  )
}

const queryFn = async ({ queryKey }: { queryKey: [string, string, IApiFilter] }) => {
  const [_, __, apiFilter] = queryKey
  return restockingApi.getOverview(apiFilter)
}

const Dashboard: FC = ({}) => {
  const { opFilters, search } = useFilteringContext()
  const { data: dashboard } = useQuery({
    queryKey: [
      'restocking',
      'overview',
      {
        filters: opFilters,
        search,
      },
    ],
    queryFn,
  })
  const tiles = [
    {
      title: 'Restock Revenue Value',
      value: dashboard ? Number(dashboard?.revenue) : '...',
      color: '#8F20FF',
    },
    {
      title: 'Restock Net Proceeds',
      value: dashboard ? Number(dashboard?.net) : '...',
      color: '#F0A30D',
    },
    {
      title: 'Restock Cost Of Goods',
      value: dashboard ? Number(dashboard?.cogs) : '...',
      color: '#52CBFF',
    },
    {
      title: 'Restock Potential Profits',
      value: dashboard ? Number(dashboard.profit) : '...',
      color: '#12B76A',
    },
  ]

  return (
    <div className="w-full overflow-x-auto mt-4 overflow-y-visible shrink-0">
      <div className="grid grid-cols-4 w-full gap-4 px-4 min-w-max overflow-y-visible">
        {tiles.map((tile, i) => (
          <DashboardTile key={i} {...tile} />
        ))}
      </div>
    </div>
  )
}

export default Dashboard
