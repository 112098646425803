import { FC, useEffect, useState } from 'react'
import { ISidebarButtonWithSubroutes, ISidebarButtonWithoutSubroutes } from '../types'
import { useLocation, useNavigate } from 'react-router'
import Tooltip from 'components/Tooltip'
import Icon from 'assets/icons/iconset'
import useTitle from 'contexts/Title/useTitle'

const RouteWithoutSubRoutes: FC<{ route: ISidebarButtonWithoutSubroutes; sidebarCollapsed: boolean, childRoute?: boolean }> = ({ route, sidebarCollapsed, childRoute }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const baseButton = ['overflow-hidden !font-medium !text-sm w-full shrink-0', sidebarCollapsed ? '[&>*:nth-child(2)]:translate-x-4 !pl-2.5 !justify-start' : 'gap-2'].asClass

  const inactiveButtonStyle = 'flex items-center justify-start bg-surface-primary text-text-secondary gap-2 py-2 px-4 rounded-lg transition-all hover:bg-surface-secondary hover:text-brand-primary'
  const activeButton = 'button-primary !justify-start'

  const isActive = childRoute ? location.pathname === route.path : location.pathname.startsWith(route.path)

  useTitle(isActive ? route.name : undefined)

  return (
    <button key={route.name} className={[baseButton, isActive ? activeButton : inactiveButtonStyle].asClass} onClick={() => navigate(route.path)}>
      <Tooltip text={sidebarCollapsed ? route.name : ''} position="right" className="ml-2 z-[1000]">
        <Icon name={route.icon} className="shrink-0 w-5 h-5" />
      </Tooltip>
      <span className="min-w-max whitespace-nowrap">{route.name}</span>
    </button>
  )
}

const RouteWithSubRoutes: FC<{ route: ISidebarButtonWithSubroutes; sidebarCollapsed: boolean }> = ({ route, sidebarCollapsed }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)
  const baseButton = ['overflow-hidden !font-medium !text-sm w-full', sidebarCollapsed ? '[&>*:nth-child(2)]:translate-x-4 !pl-[0.6125rem] !justify-start' : 'gap-2'].asClass

  const childActive = route.subRoutes?.some((subRoute) => location.pathname.startsWith(subRoute.path))

  const inactiveButtonStyle = 'flex items-center justify-start bg-surface-primary text-text-secondary gap-2 py-2 px-4 rounded-lg transition-all hover:bg-surface-secondary hover:text-brand-primary'
  const activeButton = 'button-secondary !justify-start !py-1 !border-transparent'

  const defaultNavigate = () => {
    if (childActive) return
    if (!route.subRoutes) return
    navigate(route.subRoutes[0].path)
  }

  useEffect(() => {
    if (childActive) setExpanded(true)
    else setExpanded(false)
  }, [childActive])

  return (
    <div className="flex flex-col items-center gap-2 w-full">
      <button key={route.name} className={[baseButton, childActive ? activeButton : inactiveButtonStyle].asClass} onClick={defaultNavigate}>
        <Tooltip text={sidebarCollapsed ? route.name : ''} position="right" className="ml-2 z-[1000]">
          <Icon name={route.icon} className="shrink-0 w-5 h-5" />
        </Tooltip>
        <span className="whitespace-nowrap">{route.name}</span>
      </button>
      {expanded && (
        <div className={["flex flex-col items-center w-full gap-2", sidebarCollapsed ? "" : "pl-6 border-t border-b border-brand-primary py-2"].asClass}>
          {route.subRoutes?.map((subRoute) => (
            <RouteWithoutSubRoutes key={subRoute.name} route={subRoute} sidebarCollapsed={sidebarCollapsed} childRoute />
          ))}
        </div>
      )}
    </div>
  )
}

const SidebarButton: FC<{ route: ISidebarButtonWithSubroutes | ISidebarButtonWithoutSubroutes; sidebarCollapsed: boolean }> = ({ route, sidebarCollapsed }) => {
  if ('subRoutes' in route) {
    return <RouteWithSubRoutes route={route} sidebarCollapsed={sidebarCollapsed} />
  }
  return <RouteWithoutSubRoutes route={route} sidebarCollapsed={sidebarCollapsed} />
}

export default SidebarButton
