import { AxiosInstance } from 'axios'
import axios from './axios'
import { Supplier, SupplierCreateInput, SupplierUpdateInput } from 'types/suppliers'
import { SupplierLead, SupplierLeadCreateInput, SupplierLeadUpdateInput } from 'types/supplierLeads'
import { IApiFilter } from 'types/tableFiltering'

class SuppliersApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getSuppliers = async ({ page = 1, search, filters, ordering }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/suppliers?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; suppliers: Supplier[]; search?: string; next: number | null }))
  }

  createSupplier = async (data: SupplierCreateInput) => {
    return this.axios.post('/suppliers', data).then((res) => res.data as Supplier)
  }

  updateSupplier = async (id: string, data: SupplierUpdateInput) => {
    return this.axios.put(`/suppliers/${id}`, data).then((res) => res.data)
  }

  deleteSupplier = async (id: string) => {
    return this.axios.delete(`/suppliers/${id}`).then((res) => res.data)
  }

  deleteSuppliersBulk = async ({ ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/suppliers/delete?${sp.toString()}`, { ids, except })
  }

  uploadSuppliersBulk = async (
    suppliersUri: string,
    columnMappings?: Record<
      string,
      {
        key: string
        default?: any
      }
    >
  ) => {
    return this.axios.post(`/suppliers/bulk`, { suppliersUri, columnMappings })
  }

  getLeads = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/suppliers/leads?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; leads: SupplierLead[]; search?: string; next: number | null }))
  }

  createLead = async (data: SupplierLeadCreateInput) => {
    return this.axios.post('/suppliers/leads', data).then((res) => res.data as SupplierLead)
  }

  updateLead = async (id: string, data: SupplierLeadUpdateInput) => {
    return this.axios.put(`/suppliers/leads/${id}`, data).then((res) => res.data)
  }

  deleteLead = async (id: string) => {
    return this.axios.delete(`/suppliers/leads/${id}`).then((res) => res.data)
  }

  deleteLeadsBulk = async ({ ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/suppliers/leads/delete?${sp.toString()}`, { ids, except })
  }

  uploadSupplierLeadsBulk = async (
    leadsUri: string,
    columnMappings?: Record<
      string,
      {
        key: string
        default?: any
      }
    >
  ) => {
    return this.axios.post(`/suppliers/leads/bulk`, { leadsUri, columnMappings })
  }

  pushLeadsToSuppliers = async ({ ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/suppliers/leads/to-supplier?${sp.toString()}`, { ids, except })
  }

  getNames = async () => {
    return this.axios.get('/suppliers/names').then((res) => res.data as string[])
  }

  getWebsites = async () => {
    return this.axios.get('/suppliers/websites').then(
      (res) =>
        res.data as {
          name: string
          website: string
        }[]
    )
  }
}

const suppliersApi = new SuppliersApi()

export default suppliersApi
