import { createContext } from 'react'

export type WindowSizeContextType = {
  innerWidth: number
  innerHeight: number
  isMobile: boolean
  isTablet: boolean
  orientation: 'landscape' | 'portrait'
  isTouch: boolean
}

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia("not screen and (hover)").matches
}

export const isMobileDevice = () => {
  const isMobileByWidth = window.innerWidth < 768
  const isMobileByUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const isTouch = isTouchDevice()
  return isMobileByWidth || isMobileByUserAgent || isTouch
}

const isTabletDevice = () => {
  const isTabletByWidth = window.innerWidth < 1024
  const isTabletByUserAgent = /iPad/i.test(navigator.userAgent)
  const isTouch = isTouchDevice()
  return isTabletByWidth || isTabletByUserAgent || isTouch
}

export const getWindowSize = (): string => {
  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight
  const isMobile = isMobileDevice()
  const isTablet = isTabletDevice()
  const orientation = innerWidth > innerHeight ? 'landscape' : 'portrait'
  const isTouch = isTouchDevice()

  return JSON.stringify({ innerWidth, innerHeight, isMobile, isTablet, orientation, isTouch })
}

export const WindowSizeContext = createContext<WindowSizeContextType>(
  JSON.parse(getWindowSize())
)
