import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { useMemo } from "react"
import { CreateSavedPreference, SavedPreference } from "types/user"

const useSavedPresets = <T = unknown>(group: string, enabled: boolean) => {
    const queryClient = useQueryClient()

    const {data} = useQuery({
        queryKey: ['saved-preferences', group] as const,
        queryFn: ({queryKey}) => {
            return api.getSavedPreferences<T>(queryKey[1])
        },
        enabled
    })

    const {mutateAsync: createSavedPreference, isPending: creatingSavedPreference} = useMutation({
        mutationKey: ["create-saved-preference", group] as const,
        mutationFn: async (input: Omit<CreateSavedPreference, "group">) => api.createSavedPreference({...input, group}),
        onSuccess: (created) => {
            queryClient.setQueryData(['saved-preferences', group], (old: SavedPreference[] | undefined) => {
                return old ? [...old, created] : [created]
            })
        }
    })

    const {mutate: updateSavedPreference, isPending: updatingSavedPreference} = useMutation({
        mutationKey: ["update-saved-preference", group] as const,
        mutationFn: api.updateSavedPreference,
        onSuccess: (updated) => {
            queryClient.setQueryData(['saved-preferences', group], (old: SavedPreference[] | undefined) => {
                return old ? old.map((pref) => pref.id === updated.id ? updated : pref) : [updated]
            })
        }
    })

    const {mutate: deleteSavedPreference, isPending: deletingSavedPreference} = useMutation({
        mutationKey: ["delete-saved-preference", group] as const,
        mutationFn: api.deleteSavedPreference,
        onSuccess: (_, deleted) => {
            queryClient.setQueryData(['saved-preferences', group], (old: SavedPreference[] | undefined) => {
                return old ? old.filter((pref) => pref.id !== deleted) : []
            })
        }
    })

    const submitting = useMemo(() => creatingSavedPreference || updatingSavedPreference || deletingSavedPreference, [creatingSavedPreference, updatingSavedPreference, deletingSavedPreference])

    return {
        data,
        createSavedPreference,
        updateSavedPreference,
        deleteSavedPreference,
        submitting
    }    
}

export default useSavedPresets