import Icon from 'assets/icons/iconset'
import { FC } from 'react'

interface props {
  upc: string
}

export const openUPCItemDB = (upc: string) => {
    window.open(`https://www.upcitemdb.com/upc/${upc.padStart(12, '0')}`, '_blank')
}

const UPCItemDBButton: FC<props> = ({ upc }) => {
  return (
    <button
      className="p-1 hover:bg-slate-100 rounded transition-colors"
      onClick={(e) => {
        e.stopPropagation()
        openUPCItemDB(upc)
      }}
    >
      <Icon name="Barcode" className="w-5 h-5" />
    </button>
  )
}

export default UPCItemDBButton
