import { FC } from "react";
import { IShippingCost } from "types/housedAsins";
import { IPrepCost } from "types/prepCosts";
import { IPurchaseOrder } from "types/purchaseOrders";
import { BasePrice } from "types/user";
import { usNumber } from "utils/formatting";
import { getPurchaseOrderOverview } from "utils/purchaseOrders";

export interface DashboardTileProps {
  title: string
  value: number | string
  color: string
}

interface props {
    purchaseOrder: IPurchaseOrder
    shippingCostDef: IShippingCost
    prepCosts: IPrepCost[]
    basePrice: BasePrice,
}

const DashboardTile: FC<DashboardTileProps> = ({ title, value, color }) => {
  const loading = value === '...'
  return (
    <div 
    className={[
      'w-full flex flex-col h-max p-4 gap-1 rounded-lg border shrink-0 border-border-primary grow min-w-[180px]',
      loading && 'animate-pulse'
      ].asClass}
      >
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-xs text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">
        &nbsp;{typeof value === 'string' ? value : usNumber(value)}
      </span>
    </div>
  )
}

const Dashboard: FC<props> = ({purchaseOrder, basePrice, shippingCostDef, prepCosts}) => {
    const {
        totalGrossProfit: totalNetProfit, // because reasons
        totalNetProceeds,
        totalROI,
        totalMargin,
        // totalUnitsPurchased,
        totalSellableAsins,
        totalExpenses,
        totalCOGs,
    } = getPurchaseOrderOverview({purchaseOrder, basePrice, shippingCostDef, prepCosts});

    const totalGrossProfit = totalNetProceeds - totalCOGs;
    const gpValue = `$${usNumber(totalGrossProfit)} / $${usNumber(totalGrossProfit / (totalSellableAsins || 1))}`;

    const totalProduction = totalExpenses - totalCOGs

    const eeValue = `$${usNumber(totalProduction)} / $${usNumber(totalProduction / (totalSellableAsins || 1))}`;

    const netValue = `$${usNumber(totalNetProfit)} / $${usNumber(totalNetProfit / (totalSellableAsins || 1))}`;

    const mrValue = `${usNumber(totalMargin)}% / ${usNumber(totalROI)}%`;

    return (
        <div className="w-full overflow-x-auto overflow-y-hidden">
            <div className="grid grid-cols-4 gap-4 min-w-max">
                <DashboardTile title='Gross Profit / Gross Profit Per Unit' value={gpValue} color='#8F20FF' />
                <DashboardTile title='Estimated Expenses / Prep Cost Per Unit' value={eeValue} color='#F0A30D' />
                <DashboardTile title='Net Profit / Net Profit Per Unit' value={netValue} color='#52CBFF' />
                <DashboardTile title='Net Margin / Net ROI' value={mrValue} color='#12B76A' />
            </div>
        </div>
    )
}

export default Dashboard