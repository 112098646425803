import { AxiosInstance } from "axios"
import axios from "./axios"
import { IPrepCost } from "types/prepCosts"

class PrepCostApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getPrepCosts() {
    return this.axios.get('/prepCost').then((res) => res.data as IPrepCost[])
  }
  
  async createPrepCost(name: string, amount: number) {
    return this.axios.post('/prepCost', {name, amount}).then((res) => res.data as IPrepCost)
  }
  
  async updatePrepCost(id: string, amount: number, name: string) {
    return this.axios.put('/prepCost/'+id, {amount, name}).then((res) => res.data as IPrepCost)
  }

  async deletePrepCost(id: string) {
      return this.axios.delete('/prepCost/'+id)
  }

  async deletePrepCosts({ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post('/prepCost/delete', {ids, except})
  }
}

const prepCostApi = new PrepCostApi()

export default prepCostApi
