import { FC, useCallback, useMemo, useState } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import Loader from 'components/loaders/Loader'
import Table from 'components/tables/Table'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import useAwaitableModal from 'hooks/useAwaitableModal'
import ListCatalogs from 'pages/app/housedAsins/MasterUPCCatalog/components/ListCatalogs'
import columnDef from './tableDef'
import useMasterCatalog from 'hooks/useMasterCatalog'
import withFiltering from 'contexts/Filter/wrapper'
import CreateHoused from './components/CreateHoused'
import { Row } from '@tanstack/react-table'
import housedAsinsApi from 'api/housedAsins'
import { usNumber } from 'utils/formatting'
import CreateWorkingPO from '../Housed/components/CreateWorkingPO'
import UpdatePreferencesModal from './components/UpdatePreferencesModal'
import AddAsin from './components/AddAsin'
import Icon from 'assets/icons/iconset'
import UpdateColumns from 'components/modals/UpdateColums'
import Tooltip from 'components/Tooltip'
import usePreferences from 'contexts/Preferences/useContext'
import AddEntryModal from './components/AddEntry'
import Dashboard from './components/Dashboard'
import RefreshAfterUpload from './components/RefreshAfterUpload'

const MasterUPCCatalog: FC = () => {
  const { overview, counts, catalog, setCatalog, importing, loadCatalog, loadingNextPage, importExcel, exportCSV, loadCatalogNextPage, UploadModal, saveScrollKey, supplierNames } = useMasterCatalog()
  const [listPreviousUploads, ListCatalogsModal] = useAwaitableModal(ListCatalogs, {})
  const [createHoused, CreateHousedModal] = useAwaitableModal(CreateHoused, {} as any)
  const [createWorkingPO, CreateWorkingPOModal] = useAwaitableModal(CreateWorkingPO, [])
  const uploaded = Boolean(catalog?.length)
  const [changePreferences, UpdatePreferences] = useAwaitableModal(UpdatePreferencesModal, undefined)
  const [adding, setAdding] = useState(false)
  const {preferences} = usePreferences();

  const [refreshing, setRefreshing] = useState(false)

  const [addEntry, AddEntry] = useAwaitableModal(AddEntryModal, undefined)

  const refresh = useCallback((reload = true) => {
    if (refreshing) return
    setRefreshing(true)
    housedAsinsApi.refreshMasterCatalog(reload).finally(() => setRefreshing(false))
  }, [refreshing])

  const onCreateHoused = useCallback(
    async (id: string, asin: string | null) => {
      const item = catalog?.find((i) => i.id === id && i.asin === asin)
      if (!item) return
      const items = await housedAsinsApi.getMasterCatalog({
        page: 1,
        pageSize: 100,
        filters: {
          upc: { o: '=', v: item.upc },
          ...(item.asin ? { asin: { o: '=', v: item.asin } } : {}),
        },
      })
      return createHoused({
        items: items?.items,
        asin: item.asin,
        bundle: item.bundle,
        sellable: item.sellable || 1,
      }).then(() => {
        loadCatalog({cancelRefetch: true})
      })
    },
    [createHoused, catalog, loadCatalog]
  )

  const onCreateWorkingPO = useCallback(
    async (id: string, asin: string | null) => {
      const catalogItem = catalog?.find((i) => i.id === id && i.asin === asin)
      if (!catalogItem) return
      const housedId = catalogItem.hasHoused
      if (!housedId) return
      const housedAsin = await housedAsinsApi.getHousedById(housedId)
      createWorkingPO([
        {
          ...housedAsin,
          bundle: catalogItem.bundle || housedAsin.bundle,
          sellable: catalogItem.sellable || housedAsin.sellable,
          caseQty: catalogItem.casePack || housedAsin.caseQty,
          targetPrice: housedAsin.targetPrice,
          unitCost: catalogItem.unitCost || housedAsin.unitCost,
        },
      ])
    },
    [createWorkingPO, catalog]
  )

  const setItem = useCallback(
    (item: Partial<MasterUPCCatalogItem>) => {
      const updates = {
        id: item.id!,
        asin: item.asin,
        sellable: item.sellable,
        bundle: item.bundle,
        group: item.group,
        storageType: item.storageType,
      } as MasterUPCCatalogItem
      setCatalog((old) => old?.map((i) => (i.id === item.id && i.asin === item.asin ? {
        ...i,
        ...item,
        sellable: updates.sellable || i.sellable,
        bundle: updates.bundle || i.bundle,
        storageType: updates.storageType || i.storageType,
      } : i)))
    },
    [setCatalog]
  )

  const onExport = useCallback(() => {
    const columns = preferences.tables['master-catalog']?.columns || columnDef

    const ids = columns.sort((a, b) => a.pin === b.pin ? 0 : a.pin === "left" ? -1 : 1).map((c) => c.id).filter((id) => id !== 'image')
    const mapping = ids.map((id) => ({ value: id, label: columnDef.find((c) => c.id === id)?.header as string || id }))
    exportCSV(mapping)
  }, [exportCSV, preferences])

  const renderRow = useCallback((row: Row<MasterUPCCatalogItem>) => <Item key={row.original.id + '-' + row.original.asin} row={row} updateItem={setItem} />, [setItem])

  const syncProgress = counts ? (100 * ((counts.toSync || 1) - counts.notSyncedAsins)) / (counts.toSync || 1) : null

  return (
    <div className="w-full h-full flex flex-col overflow-hidden bg-surface-light">
      <AddAsin asins={[]} open={adding} setOpen={setAdding} />
      <AddEntry />
      <Dashboard overview={overview} />
      <RefreshAfterUpload refresh={refresh} />
      <ContentLayout
        afterSearch={
          <div className="flex gap-4 items-center flex-wrap">
            <div className="text-xs text-text-secondary font-medium px-1 w-max">
              <b>{counts ? usNumber(counts.count, 0) : '...'}</b> uploaded upc{counts?.count === 1 ? '' : 's'}
              <br />
              <b>{counts ? usNumber(counts.syncedAsins, 0) : '...'}</b> asins found
              <br />
              Sync progress: <b>{syncProgress ? usNumber(syncProgress, 2) : '...'}%</b>
            </div>
          </div>
        }
        buttons={
          <div className="flex items-center gap-3 justify-end flex-wrap">
            <button className="button-secondary !p-2.5" onClick={() => refresh(true)} disabled={refreshing}>
              <Icon name="Refresh" className="h-5 w-5" />
            </button>
            <button className="button-primary !w-max" onClick={() => setAdding(true)}>
              <Icon name="Plus" />
              Add To Working PO
            </button>
            <button className="button-secondary" onClick={changePreferences}>
              Preferences
            </button>
            <button
              className="button-secondary"
              onClick={() =>
                listPreviousUploads().then((res) => {
                  if (res) loadCatalog()
                })
              }
            >
              Previous Uploads
            </button>
            <UpdateColumns table="master-catalog" columnDef={columnDef} />
            {!!uploaded && !!preferences && (
              <Tooltip text={'Manual Entry'} position="left">
                <button className="button-primary !p-2.5" onClick={() => addEntry()}>
                  <Icon name="Plus" className="w-5 h-5" />
                </button>
              </Tooltip>
            )}
            {!!uploaded && !!preferences && (
              <Tooltip text={'Export CSV'} position="left">
                <button className="button-secondary !p-2.5" onClick={onExport}>
                  <Icon name="DownloadSimple" className="w-5 h-5" />
                </button>
              </Tooltip>
            )}
            {!importing ? (
              <Tooltip text={'Import CSV'} position="left">
                <button className="button-secondary !p-2.5" disabled={!supplierNames} onClick={importExcel}>
                  <Icon name="UploadSimple" className="w-5 h-5" />
                </button>
              </Tooltip>
            ) : (
              <Loader size={24} />
            )}
          </div>
        }
      >
        <CreateHousedModal />
        <UploadModal />
        <ListCatalogsModal />
        <CreateWorkingPOModal />
        <UpdatePreferences />
        <Table
          name="master-catalog"
          columns={columnDef}
          initialPinState={{ left: ['hasHoused', 'image', 'asin', 'amazonTitle', 'supplier'] }}
          items={catalog}
          saveScrollKey={saveScrollKey}
          renderRow={renderRow}
          loading={!catalog}
          loadingNext={loadingNextPage}
          onBottom={loadCatalogNextPage}
          extra={{ onCreateHoused, onCreateWorkingPO }}
        />
      </ContentLayout>
    </div>
  )
}

export default withFiltering(MasterUPCCatalog, 'master-catalog')
