import { useState, FC, PropsWithChildren, useCallback, useEffect, useRef } from "react"
import useOperatorFilter, { OperatorFilter } from "hooks/useOperatorFilter"
import { IOrdering, IPeriodFilter } from "types/tableFiltering"
import FilteringContext from "./filteringContext"
import { isDemo } from "constants/demo"

interface Props {
  name: string
}

export interface FilterStorage {
  name?: string;
  filter?: Record<string, OperatorFilter>;
  search?: string;
  ordering?: IOrdering
  period?: IPeriodFilter
}

const defaultValues = isDemo ? {
  'master-catalog': {
    filter: {
      gross: {
        o: '>',
        v: 2,
      },
      salesRank: {
        o: 'BETWEEN',
        v: [1, 200000],
      },
      margin: {
        o: 'BETWEEN',
        v: [10, 25],
      },
      sellable: {
        o: '>',
        v: 25,
      },
    },
    search: '',
  },
  'housed-asins': {
    filter: {
      gross: {
        o: '>',
        v: 2,
      },
      salesRank: {
        o: 'BETWEEN',
        v: [1, 200000],
      },
      margin: {
        o: 'BETWEEN',
        v: [10, 25],
      },
      sellable: {
        o: '>',
        v: 25,
      },
    },
    search: '',
  }
} as Record<string, FilterStorage> : {} as Record<string, FilterStorage>

const filterStorage = (name: string) => {
  const key = ['filters', name].join('.')
  return {
    get: () => {
      const value = sessionStorage.getItem(key)
      return (value ? JSON.parse(value) : (defaultValues[name] || {})) as FilterStorage
    },
    set: (value: FilterStorage | null) => {
      if (!value) return sessionStorage.removeItem(key)
      sessionStorage.setItem(key, JSON.stringify(value))
    },
  }
}

const FilteringProvider: FC<PropsWithChildren<Props>> = ({ children, name }) => {
  const [initial, setInitial] = useState(true)

  const {filter, setFilter} = useOperatorFilter()
  const [searchState, setSearch] = useState<string>('')
  const [search, setSearchValue] = useState<string>('')
  const searchTimeout = useRef<NodeJS.Timeout | null>(null)
  const [searching, setSearching] = useState<boolean>(false)
  const [ordering, setOrdering] = useState<IOrdering | undefined>(undefined)
  const [period, setPeriod] = useState<IPeriodFilter>({since: undefined, until: undefined})

  const resetFilters = useCallback(() => {
    const storage = filterStorage(name).get()
    setFilter(storage.filter || {})
    setSearch(storage.search || '')
    setSearchValue(storage.search || '')
    setOrdering(storage.ordering || undefined)
    setSearching(false)
    setPeriod(storage.period || {since: undefined, until: undefined})
    setInitial(false)
  }, [name, setFilter, setSearchValue])
  
  useEffect(() => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(() => {
      setSearchValue(searchState)
      setSearching(false)
    }, 1000)
  }, [searchState])

  useEffect(() => {
    if (initial) return
    const value = {
      filter: filter || {},
      search: search || "",
      ordering: ordering,
      period,
    }
    const storage = filterStorage(name)
    storage.set(value)
  }, [name, initial, filter, search, ordering, period])

  useEffect(() => {
    resetFilters()
  }, [resetFilters])

  if (initial) return null

  return (
    <FilteringContext.Provider value={{ name, search, searchState, setSearch, opFilters: filter, setOpFilters: setFilter, ordering, setOrdering, searching, setSearching, period, setPeriod}}>
      {children}
    </FilteringContext.Provider>
  )
}

export default FilteringProvider