import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useSyncExternalStore } from "react";
import { getWindowSize, isMobileDevice, WindowSizeContext, WindowSizeContextType } from "./context";

const WindowSizeProvider: FC<PropsWithChildren> = ({ children }) => {
    const subscribe = useCallback((onStoreChange: () => void) => {
        window.addEventListener('resize', onStoreChange)
        return () => window.removeEventListener('resize', onStoreChange)
    }, [])

    const getter = useCallback(getWindowSize, [])

    const sizeRaw = useSyncExternalStore(subscribe, getter)
    const size = useMemo(() => JSON.parse(sizeRaw) as WindowSizeContextType, [sizeRaw])

    
  useEffect(() => {
    if (!isMobileDevice()) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach((styleSheet) => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach((rule) => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])

    return (
      <WindowSizeContext.Provider value={size}>
        {children}
      </WindowSizeContext.Provider>
    )
}

export default WindowSizeProvider