import HelpHero from "config/helphero"
import useAuthContext from "contexts/Auth/useAuthContext"
import useUserContext from "contexts/User/useUserContext"
import { HelpHero as HelpHeroType } from "helphero"
import { useEffect, useRef } from "react"

const useHelpHero = () => {
  const auth = useAuthContext()!
  const helpHero = useRef<HelpHeroType>()
  const user = useUserContext()

  useEffect(() => {
    if (!auth) {
      helpHero.current?.reset()
    } else if (user) {
        helpHero.current = HelpHero()

        helpHero.current?.identify(auth.uid, {
          created_at: auth.metadata.creationTime,
          email: auth.email,
          plan: Object.entries(user.subscriptions || {}).filter(([, value]) => value)[0]?.[0],
        })
    }
  }, [auth, user])
}

export default useHelpHero;